<template>
  <div class="pageRoot">
    <h1>Experiments</h1>
  </div>
</template>

<script>
export default {}
</script>

<style></style>
